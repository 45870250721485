<template>
    <div>

      <div class="row gutter-b">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-header card-header-fixed border-0 px-6">
              <div class="row col-9 px-0">
                
                <div class="d-flex flex-column mr-30 pt-2">
                  <!--begin: Title-->
                  <span class="card-title  font-weight-bolder font-size-h5 text-dark mb-0">{{condition.name}}</span>
                  <span class="text-muted font-weight-bold">
                    <span v-if="condition.description != ''" >{{condition.description}}</span>
                    <span v-else class="text-muted">-</span>
                  </span>
                  <!--end::Title-->
                </div>

                <div class="d-flex flex-column mr-10 pt-6 ">
                 <span 
                    v-if="condition.email_report == 1"
                    class="svg-icon svg-icon-xxl svg-icon-primary">
                    <inline-svg
                      src="/media/svg/icons/Communication/Readed-mail.svg"
                    />
                  </span>
                </div>

                <div class="d-flex flex-column mr-auto pt-7">
                 <span>
                    <b-badge variant="danger" v-if="condition.is_active == 0">{{$t("goodwemonitoring.conditions.title.unactive")}}</b-badge>
                    <b-badge variant="success" v-if="condition.is_active == 1">{{ $t("goodwemonitoring.conditions.title.active") }}</b-badge>
                  </span>
                </div>

              </div>
              <div class="card-toolbar">
                <router-link
                  :to="{ name: 'goodwemonitoring_conditions' }"
                  class="btn btn-outline-primary btn-xxl mr-3"
                >
                  {{ $t("button.back") }}
                </router-link>
                <span class="btn btn-success" @click.prevent="saveCondition" ref="save_btn">
                  {{ $t("goodwemonitoring.conditions.button.save_condition") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row gutter-b">
        <div class="col-md-4">
          <div class="card card-custom">
            <div class="card-header">
              <h3 class="card-title">
                <span class="svg-icon svg-icon-primary svg-icon-xxl mr-3" v-if="condition.type == 'BATTERY'">
                  <inline-svg class="svg-icon" src="/media/svg/icons/Devices/Battery-half.svg" />
                </span>
                <span class="svg-icon svg-icon-primary svg-icon-xxl mr-3" v-if="condition.type == 'GRID'">
                  <inline-svg class="svg-icon" src="/media/svg/icons/Code/Git2.svg" />
                </span>
                {{ $t("goodwemonitoring.conditions.title." + condition.type) }}
              </h3>
            </div>
            <div class="card-body">
              <div class="list-group">
                <div class="row">
                <div class="list-group-item col-md-6 border-right-0 border-left-0 border-top-0" v-for="(column, index) in columns" :key="index">
                  {{column}}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card card-custom gutter-b">
              <div class="card-header">
                <h3 class="card-title">
                  <span class="svg-icon svg-icon-primary svg-icon-xl mr-5 ">
                    <inline-svg src="/media/svg/icons/Code/Code.svg" />
                  </span>
                  {{ $t("goodwemonitoring.conditions.title.sql_condition") }}
                  </h3>
              </div>
              <div class="card-body">
                <textarea
                  v-model="condition.filter"
                  class="form-control form-control-lg form-control-solid"
                  rows="3"
                  :placeholder="
                    $t('goodwemonitoring.conditions.placeholder.sql_condition')
                  "
                ></textarea>
                <div class="mt-2 text-muted">
                  <p>{{ $t("goodwemonitoring.conditions.title.sql_condition_hint") }}</p>
                </div> 
              </div>
          </div>

          <div class="card card-custom">
            <div class="card-header">
                <h3 class="card-title">
                  <span class="svg-icon svg-icon-primary svg-icon-xl mr-5 ">
                    <inline-svg src="/media/svg/icons/Code/Question-circle.svg" />
                  </span>
                  {{ $t("goodwemonitoring.conditions.title.sql_condition_tips") }}
                  </h3>
              </div>
              <div class="card-body">
                <div class="alert alert-custom alert-default py-2" role="alert">
                  <div class="alert-icon">
                    <span class="svg-icon svg-icon-primary svg-icon-sm">
                      <inline-svg src="/media/svg/icons/Code/Question-circle.svg" />
                    </span>
                  </div>
                  <div class="alert-text">
                    ac_voltage_1 > 5 AND daily_generation &gt; 400
                    <br />
                    <small>{{ $t("goodwemonitoring.conditions.title.sql_condition_tips_1") }}</small>  
                  </div>
                </div>
                <div class="alert alert-custom alert-default py-2" role="alert">
                  <div class="alert-icon">
                    <span class="svg-icon svg-icon-primary svg-icon-sm">
                      <inline-svg src="/media/svg/icons/Code/Question-circle.svg" />
                    </span>
                  </div>
                  <div class="alert-text">
                    model_type LIKE '%10K-ET%' AND arm_version &gt; 7
                    <br />
                    <small>{{ $t("goodwemonitoring.conditions.title.sql_condition_tips_2") }}</small>  
                  </div>
                </div>
              </div>
            

          </div>

        </div>
      </div>

    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      loading: false,
      condition: null,
      columns: null,
    };
  },
  components: {

  },
  beforeMount() {
    this.loadCondition();
  },
  metaInfo () { 
      return { title: this.$t("goodwemonitoring.meta.conditions_detail")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("goodwemonitoring.meta.conditions"), subtitle: this.$t("goodwemonitoring.meta.conditions_detail") }]);
  },
  methods: {
    loadCondition() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/goodwe-monitoring/custom-filters/" + this.$route.params.id).then(
        (response) => {
          this.condition = response.data.data;
          this.loading = false;
          this.loadColumns(this.condition);
        }
      );
    },
    loadColumns(condition)
    {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/goodwe-monitoring/custom-filters/columns?type=" + condition.type).then(
        (response) => {
          this.columns = response.data.columns;
          this.loading = false;
        }
      );
    },
    saveCondition(){
      this.loading = true;

      ApiService.setHeader();      
      ApiService.apiPost("/goodwe-monitoring/custom-filters/" + this.condition.custom_filter_id + "/update",{
        'name': this.condition.name,
        'description': this.condition.description,
        'type': this.condition.type,
        'is_active': this.condition.is_active,
        'email_report': this.condition.email_report,
        'filter': this.condition.filter,
      })
        .then(() => {
            this.loading = false;
            this.loadCondition();
        }).catch(error => {
            checkErrors(error);
      });
    }
  }
};
</script>
