import router from "../../router";
import Toasted from 'vue-toasted';
import Vue from "vue";

Vue.use(Toasted);

export const checkErrors = function(error) {
    if(error.response.status === 401){
        router.push({ name: 'login' })
        Vue.toasted.error(error.response.data.message);
    }
    if(error.response.status === 403){
        router.push({ name: 'dashboard' })
        Vue.toasted.error(error.response.data.message);
    }
    if(error.response.status === 422){
        Vue.toasted.error("laravel validace");
        return error.response.data.errors;
    }
    if(error.response.status === 500){
        Vue.toasted.error("Něco se vyšmelilo na backendu");
        return error.response.data.errors;
    }
}

export const errorFor = function(field){
    return null != this.errors ? this.errors[field] : null;
}
